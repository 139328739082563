.genBt {
 border: 1px solid white;
 background-color: white!important;
 color: #854F67!important;
 width: fit-content;
 text-align: center;
 height: fit-content;
 padding: 1px;
 padding-left: 10px;
 padding-right: 10px;
 position: absolute;
 /* top: 65%; */
 left: 50%;
 bottom: 5%;
 /* left: 0%; */
 transform: translateX(-50%);
}

.genBt2 {
    border: 1px solid white;
    background-color: white!important;
    color: #854F67!important;
    width: fit-content;
    text-align: center;
    height: fit-content;
    padding: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    font-weight: bold;
    /* top: 65%; */
    left: 50%;
    bottom: 15%;
    /* left: 0%; */
    transform: translateX(-50%);
   }

@keyframes typing {
    
    from { 
        width: 0%; 
        white-space: nowrap;
        
    }
    to { 
        width: 60% ;
    }
}

@keyframes blink {
    from,to { 
        opacity: 0;
    }
    25% {
        opacity: .5;
    }
    50% { 
        opacity: 1; 
    }
}

.provtitle {
    /* Welcome to Adit Mehta (dot) com */

position: relative;
top: 7.5vh;
/* border-top: 3%; */
z-index: 0;
font-family: var(--heading-font);
font-style: normal;
font-weight: normal;
font-size: 3em;
margin-right: 1em;
overflow-wrap:normal;
margin-left: 1em;
line-height: 3.5rem;
text-align: center;

color: white;

text-shadow: 0px 4px 4px rgba(159, 154, 154, 0.25);

animation-name: example;
animation-duration: 2s;

}

.qTitle {
    /* Welcome to Adit Mehta (dot) com */

    position: relative;
    top: 15vh;
/* padding-top: 10%;  */
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size:  3.5rem;
line-height:  4rem;
width: 75%;
text-align: center;
margin-left: 12.5%;
color: #FFFFFF;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
max-width: 100%;
animation-name: example;
animation-duration: 2s;
overflow:hidden;
}

.dropWrap{
    z-index: 2 !important;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */

    .qTitle{
        top: 10vh;
        font-size: 2.25rem;
        line-height: 3rem;
    }
    .provtitle {
        /* padding-top: 7.5%; */
        top: 2.5vh;
        font-size: 2rem;
        line-height: 2.5rem;
    }

}