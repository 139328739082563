@import url('https://fonts.googleapis.com/css?family=Open+Sans|Quicksand');

.searchbar{
    color: #E1EFF6;
    background-color: #032B43;
    text-align: center;
    font-family: 'Quicksand';
    font-weight: 500;
    border-color: white;
    padding-top: 1%;
    padding-bottom: 1%;
}

.rtbl{
    background-color: #136F63;
}

.rdescript{
    color: lightgrey;
}

.rentry{
    font-family: raleway;
    color: white;
    font-weight: 600;
    text-decoration: none;
}

.rentry:hover{
    color: white;
}

.recipietitle{

    margin-top: 0%;
    
    
    font-family: var(--heading-font);;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 105px;
    text-align: center;
    
    color: #E1EFF6;
    background-color: #032B43;
    }