@import url('https://fonts.googleapis.com/css?family=Shrikhand|Raleway');
@import url('https://fonts.googleapis.com/css?family=Unica+One');
@font-face {
    font-family: 'Lust';
    src: url('../lust-slimfont-family/Lust\ Slim.otf') format('opentype'),
         url('../lust-slimfont-family/Lust\ Slim.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

:root {
    --main-font: 'Raleway';
    --heading-font: 'Lust';
    --monospace-font: 'Courier New, Courier, monospace';
  }

@keyframes example {
    from {opacity: 0;
        filter: blur(10px);}
    to {opacity: 1;}
  }
.title {
    /* Welcome to Adit Mehta (dot) com */

position: absolute;
left: 0%;
right: 0%;
top: 7.5%;
bottom: 0%;

font-family: var(--heading-font);
font-style: normal;
font-weight: normal;
font-size: 64px;
line-height: 70px;
text-align: center;

color: #FFFFFF;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

animation-name: example;
animation-duration: 2s;
}


.line1 {
/* Line 1 */

position: absolute;
left: 0%;
right: 0%;
top: 55%;
bottom: 0%;

width: 30%;
border: 1px solid #FFD200;
transform: rotate(90deg);

}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: gold black;
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }
  
  *::-webkit-scrollbar-track {
    background: black;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: gold;
    border-radius: 20px;
    border: 3px solid black;
  }

.prot{
    
    position: absolute;
    left: 0%;
    right: 50%;
    top: 70%;
    bottom: 0%;

    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 4rem;
    line-height: 75px;
    text-align: center;
    color: #FFFFFF;
}

.pert{
    
    position: absolute;
    left: 50%;
    right: 00%;
    top: 70%;
    bottom: 0%;
    
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 4em;
    line-height: 75px;
    text-align: center;
    color: #FFFFFF;
}

.proi{
    max-width: 9rem;
    position: relative;
    left: 20%;
    padding-top: 50vh;
    text-align: center;

}

.peri{
    max-width: 10rem;
    position: absolute;
    left: 70%;
    text-align: center;
}

.proi:hover,.peri:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
transform: scale(1.4);
}

.span2 {
    font-size:125%;
    color: #FFD200;
}

.hiddenbutton {
    visibility: hidden;
}

.sender{
    position: absolute;
    left: 00%;
    right: 00%;
    bottom: 0%;
    padding-bottom: 1%;
}

.sendinput {
    margin-left: 1em;
    margin-right: 5%;
    width: 70%;
    font-size: 1.5em;
}

.sendbt{
    font-size: 1.5em;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .title {
        font-size: 2rem;
    }

    .prot {
        font-size: 1.5em;
    }
    .pert {
        font-size: 1.5em;
    }

    .proi {
        left: 10%
    }
    .peri {
        left: 60%
    }
}