@import url('https://fonts.googleapis.com/css?family=Shrikhand|Raleway');

.adminsearchbar{
    color: #E1EFF6;
    background-color: black;
    text-align: left;
    /* font-family: 'Quicksand'; */
    font-weight: 300;
    border-color: white;
    padding-top: 1%;
    padding-bottom: 1%;
}

.lineDateInput{
    font-family: Raleway;
    background-color: transparent;
    color: white;
    border: 0px;
}

.shortDate{
    width: 10ch;
    /* width: fit-content; */
}

.tabar{
    color: #ffd600;
    
    font-family: raleway;
    font-weight: 600;
}

.aditsubmit{
    background-color: transparent;
    position: absolute;
    right: 5%;
    top: 15%;
    font-family: raleway;
    color: #ffd200;
    border-color: #ffd200;
}


.firstNameInput, .lastNameInput{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    
    width: 10vw;
    color: white;
    background-color: transparent;
    border-color: transparent;
}

.affInput{
    font-family: Raleway;
font-size: 20px;
font-style: italic;
font-weight: 400;
text-align: left;
width: 25vw;
color: white;
width: fit-content;
background-color: transparent;
border-color: transparent;
}

.locInput{
    background-color: transparent;
    color: white;
    font-size: 15px;
    border: 0px;
    font-family: Raleway;
    font-style: oblique;
}

.locTitle{
    font-family: Shrikhand;
    text-align: center;
    font-size: 50px;
}

.areaTitle{
    font-family: Shrikhand;
    font-size: 30px;
    text-align: center;
    color: gold;
}

.crmPad{
    padding-right: 10px;
}

.custGroup{
        border-radius: 10px;
        margin-bottom: 2em;
        position: relative;
        border:.5px solid;
        padding: 10px;
        border-color: white;
        background: #032b43;
        vertical-align: middle;
        width: 375px;
        /* display: inline-block; */
}

.giftInput{
    background-color: transparent;
    font-family: Raleway;
    border-color: transparent;
    color: white;
    width: 250px;
    margin-top: 2%;
    font-size: 1em;
}

.addressInput{
    background-color: transparent;
    font-family: Raleway;
    border-color: transparent;
    color: white;
    width: 250px;
    margin-top: 2%;
    font-size: 1em;
}

.commentInput{
    background-color: transparent;
    font-family: Raleway;
    border-color: transparent;
    color: white;
    width: 250px;
    margin-top: 2%;
    font-size: 1em;
}
.bdayInput{
    font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 25px;
color: #FFD600;
background-color: transparent;
border-color: transparent;

}


.modCRMlog{
    background: black;
    border-color: #FFD600; 
    position: fixed; 
    overflow: auto;
    max-height: 60vh;
    z-index: 200;
    max-width: fit-content;
}

.ent{
    font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 20px;
color: #FFFFFF;
}

.statusInput{
    font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 15px; 
background-color: darkgrey;
}

.lastRInput , .ccodeInput,.phInput, .lastMInput, .nextInput{
    background-color: black;
    color: grey;
    font-size: 15px;
    width: 100px
}

.newnote{
    background-color: transparent;
    border-color: white;
    color: white;
    font-family: raleway;
}


.bt3{
    background-color: transparent;
    border-color: red;
    font-family: raleway;
    color: red;
    border-width: 1px;
}

.bt2{
    background-color: transparent;
    border-color: white;
    font-family: raleway;
    color: white;
    border-width: 1px;
}

.circleInput{
    display:block;
    width:4.5em;
    height:4.5em;
    border-radius:4.5em;
    border-color: #ffd200;
    font-size:10px;
    color:#fff;
    text-align:center;
    background:#000;
}

.circleInputLive{
    display:block;
    width:4.5em;
    height:4.5em;
    border-radius:4.5em;
    border-color: #ffd200;
    font-size:10px;
    color:#fff;
    text-align:center;
    background:#ffd200;
}

.fullnote:hover td{
    background-color: #ffd200;
    color: white;
    border-color:white;
}

.fullnote:hover .bt2{
    background-color: white;
    color: #00ff00;
    border-color:white;
}

.fullnote:hover .bt1{
    background-color: white;
    color: #8542F2;
    border-color:white;
}

@media only screen and (max-width: 768px) {
.aditsubmit{
    top: 9%;
}
.firstNameInput{

    width: 20vw;
}
.lastNameInput {

    width: 20vw;
}
.affInput{

    width: 35vw;
}
.bdayInput{
    width: 35vw;
}
.modCRMlog{
    height: 35vh;
}
}