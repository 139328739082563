/* Button to open navigation */
.nav-toggle {
    font-size: 30px;
    cursor: pointer;
    background: none;
    border: none;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    color: #ffd600;
    /* background-color: black; */
  }
  
  /* Full-screen overlay */
  .fullscreen-nav {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.95);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-100%);
    transition: transform 0.5s ease;
    z-index: 999;
    visibility: hidden;
  }
  
  /* Open state */
  .fullscreen-nav.open {
    transform: translateY(0);
    visibility: visible;
    z-index: 9999;
  }
  
  /* Close button */
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 40px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  /* Home icon positioned parallel to the close button */
  .home-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    
    font-size: 30px;
    text-decoration: none;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .home-icon:hover {
    color: #f0a500;
  }
  
  .home-icon .nav-icon {
    font-size: 30px; /* Same size as the close button */
    margin-bottom: 5px;
  }
  
  .home-icon .nav-title {
    font-size: 24px;
  }
  
  /* Navigation links container with grid layout */
  .nav-links {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 1200px;
    margin: auto;
  }
  
  /* Individual navigation items */
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 24px;
    text-align: center;
    transition: color 0.3s ease;
    cursor: pointer;
  }
  
  .nav-item:hover {
    color: #f0a500;
  }
  
  .nav-icon {
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  .nav-title {
    font-size: 24px;
    margin-bottom: 5px;
  }
  
  .nav-description {
    font-size: 16px;
    opacity: 0.7;
  }
  