@keyframes example {
    from {
        opacity: 0;
        filter: blur(10px);
    }
    to {
        opacity: 1;
    }
}

@keyframes typing {
    
    from { 
        width: 0%; 
        white-space: nowrap;
        
    }
    to { 
        width: 60% ;
    }
}

@keyframes blink {
    from,to { 
        opacity: 0;
    }
    25% {
        opacity: .5;
    }
    50% { 
        opacity: 1; 
    }
}
.headshot{
    position: absolute;
    right: 10%;
    top: 5%;
    color: grey;
    font-family: Raleway;
    text-align: center;
}

.proprofile{
    /* position: absolute;
    right: 10%;
    top: 3%; */
    width: 25vw;
    
    margin-bottom: 7px;
    border-radius: 164.5px;
}

.head{
    position: relative;
    margin-left: 10%;
    margin-top: 5%;
    font-family: var(--heading-font);;
font-style: normal;
font-weight: normal;
font-size: 64px;
line-height: 64px;

color: #FFD600;
}

.subhead{
    font-family: Raleway;
    font-style: normal;
font-weight: normal;
font-size: 35px;
line-height: 35px;
color: white;
margin-left: 10%;
}

.abt{
    font-family: Raleway;
    font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 20px;
color: white; 
margin-top: 5%; 
margin-left: 10%;
margin-right: 40%;
display: block;
overflow: hidden;
letter-spacing: 2px;
}
.scroll{
    color: white;
    font-family: var(--heading-font);;
    text-align: center;
    font-size: 30px;
    position: absolute;
    bottom: 10%;
    left: 0%;
    right: 0%;
    animation: blink 3s linear  infinite;
}

.subhead:hover{
    color:grey;
}


.break {
    color: gold;
    text-align: center;
}

.frame {
    height: 100vh;
}

.sec{
    color: gold;
    font-family: var(--heading-font);;
    font-size: 80px;
}

.resume{
    border: 1px solid;
}

.resume2{
    margin-left: 10%;
}


.header{
    font-family: var(--heading-font);;
    color: white;
    font-size: 1.5em;
}

.role{
    font-family: Raleway;
    font-size: 2em;
    margin-bottom: 0em;
    font-weight: 900;
    color: white;
}
.time{
    font-family: Raleway;
    color: grey;
    font-style: italic;
}

.descript{
    font-family: Raleway;
    white-space: pre-line;
    color: white;
    
}

.details {
    
    border-radius: 10px;
    margin-bottom: 2em;
    position: relative;
    left: 0%;
right: 0%;
    border:.5px solid;
    padding: 15px;
    border-color: white;
    width: 75vw;
    vertical-align: middle;
}

.row{
    text-align: left;
    vertical-align: middle;
}

.sdb{
    display: block;
    color: white;
  }
  
  .s {
    display: inline-block;
    color: white;
    font-family: Raleway;
    border: 1px solid;
    margin-bottom: 2em;
    margin-right: 2em;
  }

  .s:hover{
      background-color: gold;
  }

  .profooter {
    background-color: white;
    text-align: center;
    padding: 5px;
}
  
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .head {
        font-size: 3rem;
    }

    .sec  {
        font-size: 2.5rem;
    }
    .subhead {
        font-size: 1em;
    }

    .abt{
        font-size: .7em;
    }

    .role{
        font-size: 1.5em;
    }

    .header{
        font-size: 1em;
    }

    .headshot{
        position: absolute;
        top: 30%;
        right: 5%;
        color: grey;
        font-size: .5em;
        font-family: Raleway;
    }

    .descript {
        font-size: 1em;
    }
    .proprofile{
        width: 30vw;
        margin-bottom: 7px;

    }
    .scroll {
        font-size: 1em;
    }
}