.rLink{
    background-color: pink;

}

.rLbl{
    font-size: 20px;
    text-decoration: underline; 
    font-family:var(--heading-font);;
    padding-top: 1%;
    color: white;
}

.rAlt{
    color: #1A4861;
    -webkit-text-stroke-width: .5px;
    -webkit-text-stroke-color: #1A4861;
}

.rMain{
    color: #fff;
}

.rFocus{
    padding-left: 10px;
    background-color: #136F63;
}

.rTitle{
    
background-color: #1A4861;
/* display: table-row; */
}


.align{
    /* display: table-cell; */
    text-align: center;
}

.rImage{
    border: 10px solid  #fff; 
    /* max-width: 100%; */
    width: 40%;

}

.ingred{
    color: white;
    margin-top: 1em;
    border-radius: 5px;
    /* padding-bottom: 1%; */
    padding-left: 2.5%;
    /* border: .5px solid pink; */
    border-bottom: .5px solid pink;
}

.ingred:hover{
    background-color: white;
    background-color: #1A4861;
 
}

.rTopic{
  
    padding-top: 2.5%;
    
    
    font-family: var(--heading-font);;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 105px;
    text-align: center;
    line-height: 50px;
    color: #E1EFF6;
}

.rDes{
    font-family: "Open Sans";
    color: #fff; 
    font-size: 15px;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
}

.rInfo{
    font-family: "Raleway";
    color: #fff; 
    font-size: 15px;
    padding-top: 1.5%;
}

.rLi{
    overflow-wrap: break-word;
}