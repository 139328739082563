@import url('https://fonts.googleapis.com/css?family=Open+Sans|Raleway');

.blogtitle{

margin-top: 0%;


font-family: var(--heading-font);;
font-style: normal;
font-weight: normal;
font-size: 50px;
line-height: 95px;
text-align: center;

color: gold;
background-color: #1A4861;
}

.blogpost{
background-color: #226081;
border: 10px solid  #1A4861;
}

.blogttl{
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 95px;

}

.blogdate{
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px; 
}

.align{
    text-align: center;
}

.blogimage{
    border: 20px solid  #272838; 
    width: 70vw;
}

.aller{
    font-family: "Raleway";
    text-align: right;

}

.blogdetes{
    width: 100%;
    height: fit-content;
    background-color: transparent;
    color: white;
    font-family: 'Raleway';
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    overflow-wrap: break-word;
    border: none;
    white-space: pre-wrap;
}

